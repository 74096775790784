<template>
  <v-container class="py-6">
    <v-responsive max-width="600" class="mx-auto text-center">
      <h1 class="text-h3 mb-4">Reset Your Password</h1>
      <p>Please enter your email address below and we'll send you a link to reset your password.</p>
    </v-responsive>
    <v-card class="pa-4 mx-auto" max-width="400" flat>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="Email"
          required
          solo
          outlined
          :disabled="isDisabled"
        ></v-text-field>
        <v-btn
          :disabled="!valid || isDisabled"
          block
          color="primary"
          x-large
          @click="requestResetLink"
        >
          Send Reset Link
        </v-btn>
        <v-alert
          v-if="message"
          :type="error ? 'error' : 'success'"
          class="mt-4"
        >
          {{ message }}
        </v-alert>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      message: '',
      error: false,
      valid: false,
      isDisabled: false,
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
    };
  },
  methods: {
    async requestResetLink() {
      this.isDisabled = true;
      this.message = 'Trying to send a reset email...';
      this.error = false;
      if (this.$refs.form.validate()) {
        try {
          const response = await fetch(`https://${this.$serverAddress}/api/users/forgot-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: this.email,
              resetLinkRoot: `${window.location.origin}/resetPassword/`,
            }),
          });
          const data = await response.json();
          if (response.ok) {
            this.message = data.message;
          } else {
            this.error = true;
            this.message = data.message || 'Error occurred while trying to send reset link';
          }
        } catch (error) {
          console.error('Fetch error:', error);
          this.error = true;
          this.message = 'Internal server error';
        }
      }
    },
  },
};
</script>

<style scoped>
.v-alert {
  width: 100%;
}
</style>
